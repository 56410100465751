import "../styles/globals.css";
import "../styles/Calander.module.css";
import "../sass/styles/_styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "../public/DatePicker.css";
import "../public/Calendar.css";
import "../public/DateRangePicker.css";
import "react-daterange-picker/dist/css/react-calendar.css";
import "react-calendar/dist/Calendar.css";
import "react-phone-number-input/style.css";
import { ThemeProvider } from "@mui/material/styles";
import { library } from "@fortawesome/fontawesome-svg-core";
import CssBaseline from "@mui/material/CssBaseline";
import {
  faStar,
  faHeart,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEnvelope,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faSearch,
  faArrowRight,
  faPhoneAlt,
  faPaperclip,
  faPaperPlane,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import React, { Suspense } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SSRProvider from "react-bootstrap/SSRProvider";
import Injector from "../components/Injector";

// /redux
import { Provider } from "react-redux";
import theme from "../styles/theme";

import { configureAppStore } from "../store/configureStore";

import { DefaultSeo } from "next-seo";
import { BASE_URL } from "../environment";
import { path } from "../utils/Api";
import GoogleAnalytics from "../utils/GoogleAnalytics";
import ReCaptchaWarper from "../components/ReCaptchaWarper";
import Head from "next/head";

const store = configureAppStore();
library.add(
  faEnvelope,
  faSearch,
  faCalendar,
  faStar,
  faHeart,
  faArrowRight,
  faPhoneAlt,
  faExternalLinkAlt,
  faPaperclip,

  faPaperPlane,
  faChevronDown,
  faChevronLeft,
  faChevronRight
);

function RouteGuardComponent({ pageProps, Component, children }: any) {
  return (
    <>
      <Head>
        <meta
          name="google-site-verification"
          content="n50ShG3kN7tIDfl4JUgkBROGjebzZaJaEnzTF0nJmdM"
        />
      </Head>

      <DefaultSeo
        canonical={BASE_URL}
        openGraph={{
          title: pageProps.title,
          description: pageProps.description,
          type: "website",
          locale: "en_IE",
          url: BASE_URL,
          siteName: "Yoga Retreat",
          images: [
            {
              url: pageProps.image
                ? pageProps.image
                : `${path()}site_setting_files/a17bd84b-86fc-46b2-b764-c366183b4480.png`,
              alt: pageProps.title,
              width: 800,
              height: 600,
              type: `image/${
                pageProps.image
                  ? pageProps.image.split(".")[
                      pageProps.image.split(".").length - 1
                    ]
                  : "png"
              }`,
            },
          ],
        }}
        defaultTitle={pageProps.title}
        description={pageProps.description}
      />
      {children}
    </>
  );
}

function MyApp({ Component, pageProps }: any) {
  const CloseButton = ({ closeToast }: any) => (
    <FontAwesomeIcon
      icon={faTimesCircle}
      onClick={closeToast}
      className="toast-close"
      width="20"
    />
  );

  return (
    <SSRProvider>
      <Provider store={store}>
        <Injector>
          <RouteGuardComponent pageProps={pageProps} Component={Component}>
            <Suspense>
              <GoogleAnalytics />
             
              <ReCaptchaWarper>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <ToastContainer closeButton={CloseButton} />
                  <Component {...pageProps} />
                 
                </ThemeProvider>
              </ReCaptchaWarper>
            </Suspense>
          </RouteGuardComponent>
        </Injector>
      </Provider>
    </SSRProvider>
  );
}

export default MyApp;
