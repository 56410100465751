import styles from "../styles/Home.module.css";
import MainLayout from "../components/layout/main-layout";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ProductCard from "../components/product-card";
import ReviewBlock from "../components/ReviewBlock";
import Search from "../components/UI/Search";
import { useDispatch, useSelector } from "react-redux";
import DiscoverRetreats from "../components/UI/DiscoverRetreats ";
import StillSearching from "../components/UI/StillSearching";
import { path } from "../utils/Api";
import UniqueExperiencesSec from "../components/UniqueExperiencesSec";
import { actions } from "../redux/Admin/SettingSection/SiteSetting/redux/slice";
import { actions as actionsUpdate } from "../redux/Frontend/ListingsFilter/redux/slice";
import { v4 as uuid } from "uuid";
import { actions as filterindex } from "../redux/Frontend/ListingsFilter/redux/slice";
import {
  RetreatSortTypeEnum,
  RetreatsTypeEnum,
} from "../redux/Frontend/ListingsFilter/types";
import { selectSchoolId } from "../redux/Login/redux/selector";
import { parseCookies } from "../utils/cookies";
import {
  GetSiteSettingServerSideRequest,
  getFeaturedRetreatsAndTeacherTraingingCard,
} from "../utils/request";
import { NextSeo } from "next-seo";
import { BASE_URL } from "../environment";
import { log } from "../utils/logger";
export default function Home({ data, adminsideData, title, description }: any) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [showChild, setShowChild] = useState(false);
  const _handleroute = (retreatTypeFilter: any, activityFilter: any) => {
    let qr = { retreatTypeFilter, activityFilter };
    !qr.retreatTypeFilter && delete qr.retreatTypeFilter;
    !qr.activityFilter && delete qr.activityFilter;
    dispatch(
      actionsUpdate.updateListingsFilterFormValue({
        key: "retreatTypeFilter",
        value: parseInt(qr.retreatTypeFilter) || [],
      })
    );

    dispatch(
      actionsUpdate.updateListingsFilterFormValue({
        key: "activityFilter",
        value: qr.activityFilter,
      })
    );
    dispatch(
      actionsUpdate.updateListingsFilterFormValue({
        key: "sortData",
        value: RetreatSortTypeEnum.Recommended,
      })
    );
    router.push({
      pathname: "/listing",
      query: qr,
    });
  };
  const _handleChange = () => {
    dispatch(filterindex.setHandelChange(1));
  };
  const handleFeatured = (evt: any, activityFilter: any) => {
    let qr = { retreatFeatured: evt, activityFilter };
    dispatch(
      actionsUpdate.updateListingsFilterFormValue({
        key: "retreatFeatured",
        value: evt,
      })
    );
    if (qr.activityFilter?.length > 0) {
      dispatch(actionsUpdate.setActivityList(qr.activityFilter));
    }

    router.push({
      pathname: "/listing",
      query: qr,
    });
  };
  useEffect(() => {
    setShowChild(true);
  }, []);
  const fetchImageData = () => {
    dispatch(actions.doGetSiteSettingList());
  };
  useEffect(() => {
    fetchImageData();
  }, []);
  const LoggedinUser = useSelector(selectSchoolId);
  const unique_id = uuid();
  let id: any = "";
  useEffect(() => {
    id = localStorage.getItem("userid");
    return () => {};
  }, []);
  let token: any = "";
  useEffect(() => {
    token = localStorage.getItem("token");
    return () => {};
  }, []);
  useEffect(() => {
    dispatch(actions.setPageNo(1));
    dispatch(actions.doGetSiteSettingList());
    return () => {};
  }, []);
  useEffect(() => {
    if (token) {
      localStorage.setItem("userid", LoggedinUser);
    } else {
      if (!id) {
        localStorage.setItem("userid", unique_id);
      } else {
        return;
      }
    }
  }, [LoggedinUser, token]);
  if (!showChild) {
    return null;
  }
  if (typeof window === "undefined") {
    return <></>;
  } else {
    return (
      <div>
        {/* <Tawk /> */}
        <NextSeo
          title={title}
          description={description}
          canonical={BASE_URL}
          openGraph={{
            url: router.pathname,
            title: "Book Your Next Yoga Retreat & Enjoy A Trusted Experience.",
            description:
              "Experience the most amazing yoga retreats with 100+ retreat options available at Book Your Retreat. Check with reviews and get trusted feedback before booking. ",
            images: [{ url: `${path()}${adminsideData.favicon}` }],
            siteName: "Yoga Retreat",
          }}
        />
        <MainLayout currencshow={true} adminsideData={adminsideData}>
          <div className={`main-container`}>
            <main
              className={`${styles.main} pt-5`}
              style={{
                backgroundImage: `url(${path()}${adminsideData.homepageImage})`,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  background: "rgb(0 0 0 /38%)",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  // display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className={`${styles.headerContainer}`}>
                  <div className="container">
                    <h1 className="header-title text-white fw-bold text-center text-capitalize">
                      Break Free From Routine.
                    </h1>
                    <p className="mt-1 text-white header-paragraph text-center fw-500 px-sm-5 px-md-2 px-lg-0 text-capitalize">
                      Enrich Your Life with Unforgettable Retreats and Yoga
                      Teacher Training Programs
                    </p>
                  </div>
                  <div className="container mt-3 mt-sm-5 byr-category-button-wrap">
                    <div className="row">
                      <div className="col-sm-0 col-lg-1 col-xl-2 col-12"></div>
                      <div className="col-sm-12 col-lg-10  col-xl-8 col-12">
                        <div className="row p-0 mb-2">
                          <div className=" scroll-bar d-flex justify-content-between flex-wrap ">
                            <div className="text-center mt-2 byr-banner-button-categories">
                              <p
                                className={`fw-semibold byr-banner-yoga-categories textmobile text-white BottomBorder text-underline pointer mb-0`}
                                style={{ textShadow: ".3px 0px #ffffff" }}
                                onClick={() =>
                                  _handleroute(
                                    RetreatsTypeEnum.Offline,
                                    "Yoga Retreat"
                                  )
                                }
                              >
                                Yoga Retreat
                              </p>
                            </div>
                            <div className="text-center mt-2 byr-banner-button-categories">
                              <p
                                className="textmobile byr-banner-yoga-categories fw-semibold BottomBorder text-white pointer mb-0"
                                style={{ textShadow: ".3px 0px #ffffff" }}
                                onClick={() =>
                                  _handleroute(
                                    RetreatsTypeEnum.Offline,
                                    "Yoga Teacher Training"
                                  )
                                }
                              >
                                Yoga Teacher Training
                              </p>
                            </div>
                            <div className="text-center mt-2 byr-banner-button-categories">
                              <p
                                className="textmobile byr-banner-yoga-categories fw-semibold text-white pointer mb-0"
                                style={{ textShadow: ".3px 0px #ffffff" }}
                                onClick={() =>
                                  _handleroute(
                                    RetreatsTypeEnum.Online,
                                    "Yoga Retreat"
                                  )
                                }
                              >
                                {" "}
                                Yoga Online Retreats
                              </p>
                            </div>
                            <div className="text-center mt-2 byr-banner-button-categories">
                              <p
                                className="textmobile byr-banner-yoga-categories fw-semibold text-white pointer mb-0"
                                style={{ textShadow: ".3px 0px #ffffff" }}
                                onClick={() =>
                                  _handleroute(
                                    RetreatsTypeEnum.Online,
                                    "Yoga Teacher Training"
                                  )
                                }
                              >
                                Online Yoga Training
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-0 col-lg-1 col-12"></div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-0 col-lg-1 col-xl-2 col-12"></div>
                      <div className="col-sm-12 col-lg-10 col-xl-8 col-12">
                        <div className="row p-0">
                          <div className=" homepage " style={{ height: 60 }}>
                            <Search
                              homePage={true}
                              _handleChange={_handleChange}
                              customSearch="col-md-6 col-lg-6 col-12 d-flex  bg-white mb-1 mb-sm-0 py-2"
                              customDate="col-md-3 col-lg-3 bg-white mt-2 mt-md-0 py-2"
                              customButton="col-md-3 col-lg-3 mt-2 mt-md-0 "
                              customSearchSuggesion="col-12 col-md-6"
                              customDateSuggestion="col-12 col-md-6"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-0 col-lg-1 col-12"></div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            {/* <div id="byr-banner-benefits">
              <p className="byr-banner-benfits-box">
                <img
                  src="../images/byr-best-price.svg"
                  alt=""
                  className="byr-banner-benefit-icon"
                />
                Guaranteed Best Prices
              </p>
              <p className="byr-banner-benfits-box">
                <img
                  src="../images/byr-cancellation-policy.svg"
                  alt=""
                  className="byr-banner-benefit-icon"
                />
                Easy Cancellation Policy
              </p>
              <p className="byr-banner-benfits-box">
                <img
                  src="../images/byr-no-charging-fee.svg"
                  alt=""
                  className="byr-banner-benefit-icon"
                />
                No Charging Fees
              </p>
              <p className="byr-banner-benfits-box">
                <img
                  src="../images/byr-referral.svg"
                  alt=""
                  className="byr-banner-benefit-icon"
                />
                Amazing Referral Revenue
              </p>
            </div> */}
            <div className={`${styles.featured} mt-2`}>
              <div className="container" style={{ position: "relative" }}>
                <h2 className="text-sm-left text-md-center text-lg-center feature-title fw-bold mb-2 ">
                  Featured Yoga Retreats
                </h2>
                <p className="mb-2 p-0 text-sm-left text-md-center text-lg-center fw-400 feature-paragraph brownish-grey">
                  Take a break from life and choose from our extraordinary range
                  of retreats that will soothe your soul.
                </p>
                <div
                  className={`${styles.btn_container} d-none d-sm-none d-md-block d-lg-block `}
                >
                  <p
                    className={`btn btn-outline-primary fw-500 rounded-pill fs-12 px-4 btnHoverNew`}
                    onClick={() => handleFeatured(1, "Yoga Retreat")}
                  >
                    View All
                  </p>
                </div>
                <div className="row mt-3 mt-md-5">
                  {data?.featuredRetreats
                    ?.slice(0, 4)
                    .map((item: any, index: any) => {
                      return (
                        <ProductCard
                          key={index}
                          retreatId={item.retreatId}
                          wishlistId={item.wishlists}
                          type={item.activity}
                          overview={item.title}
                          price={item.price}
                          reviewScore={item.rating}
                          noOfReviews={item.numberOfReviews}
                          image={item.file}
                          coupon={item.couponAvailble}
                          gift={item.freeGift}
                          popular={item.popular}
                          seoTitle={item.seoTitle}
                          seoActivityTitle={item.seoActivityTitle}
                        />
                      );
                    })}
                </div>
                <div
                  className={`col-12 mt-0 d-flex align-items-center justify-content-center d-sm-flex d-md-none d-lg-none`}
                >
                  <p
                    className={`btn btn-outline-primary fw-500 rounded-pill fs-12 px-4 btnHoverNew`}
                    onClick={() => handleFeatured(1, "Yoga Retreat")}
                  >
                    View All
                  </p>
                </div>
              </div>
            </div>
            <section id="byr-why-choose-us" className="bg-footer-grey">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <h2 className="text-sm-left text-md-center text-lg-center feature-title fw-bold textUpperCase">
                      Why Use Book Your Retreats?
                    </h2>
                    <p className="text-sm-left text-md-center text-lg-center feature-paragraph fw-400 mb-4 paragraph">
                      We promise to deliver the best & most authentic retreats.
                      Professional trainers offer these retreats with immense
                      knowledge & experience.
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <div className="byr-why-choose-box">
                      <img
                        src="../images/byr-best-price.svg"
                        alt=""
                        className="byr-why-choose-image"
                      />
                      <h3 className="byr-why-choose-heading">
                        Guaranteed Best Prices
                      </h3>
                      <p className="byr-why-choose-text">
                        Hosts offer less price to be listed on our website,
                        saving a lot for you.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="byr-why-choose-box">
                      <img
                        src="../images/byr-cancellation-policy.svg"
                        alt=""
                        className="byr-why-choose-image"
                      />
                      <h3 className="byr-why-choose-heading">
                        Easy & Free Cancellation Policy
                      </h3>
                      <p className="byr-why-choose-text">
                        Our cancellation policy is FREE, effortless & suitable
                        for all.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="byr-why-choose-box">
                      <img
                        src="../images/byr-no-charging-fee.svg"
                        alt=""
                        className="byr-why-choose-image"
                      />
                      <h3 className="byr-why-choose-heading">
                        No Extra Booking Fee
                      </h3>
                      <p className="byr-why-choose-text">
                        There is absolutely no booking fee if you book through
                        us.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="byr-why-choose-box">
                      <img
                        src="../images/byr-referral.svg"
                        alt=""
                        className="byr-why-choose-image"
                      />
                      <h3 className="byr-why-choose-heading">
                        Amazing Referral Revenue
                      </h3>
                      <p className="byr-why-choose-text">
                        Unlock great opportunities & generate easy passive
                        income.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className={`${styles.featured} `}>
              <div className="container" style={{ position: "relative" }}>
                <h2 className="text-sm-left text-md-center text-lg-center feature-title fw-bold mb-2 ">
                  Featured Yoga Teacher Trainings
                </h2>
                <p className="text-sm-left text-md-center text-lg-center feature-paragraph fw-400 mb-2 brownish-grey">
                  Go deeper into your practice and experience an immersive
                  teacher training program.
                </p>
                <div className={`${styles.btn_container} d-none d-md-block`}>
                  <p
                    className={`btn btn-outline-primary fw-500 rounded-pill fs-12 px-4 btnHoverNew`}
                    onClick={() => handleFeatured(1, "Yoga Teacher Training")}
                  >
                    View All
                  </p>
                </div>
                <div className="row mt-3 mt-md-5">
                  {data?.featuredTeacherTraining
                    ?.slice(0, 4)
                    .map((item: any, index: any) => {
                      return (
                        <ProductCard
                          key={index}
                          retreatId={item.retreatId}
                          wishlistId={item.wishlists}
                          type={item.activity}
                          overview={item.title}
                          price={item.price}
                          reviewScore={item.rating}
                          noOfReviews={item.numberOfReviews}
                          image={item.file}
                          coupon={item.couponAvailble}
                          gift={item.freeGift}
                          popular={item.popular}
                          seoTitle={item.seoTitle}
                          seoActivityTitle={item.seoActivityTitle}
                        />
                      );
                    })}
                </div>
                <div
                  className={`col-12 mt-0 d-flex align-items-center justify-content-center d-sm-flex d-md-none d-lg-none`}
                >
                  <p
                    className={`btn btn-outline-primary fw-500 rounded-pill fs-12 px-4 btnHoverNew`}
                    onClick={() => handleFeatured(1, "Yoga Teacher Training")}
                  >
                    View All
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 bg-footer-grey py-4 py-sm-4 py-md-5 py-lg-5">
              <div className="container" style={{ position: "relative" }}>
                <h2 className="text-sm-left text-md-center text-lg-center feature-title fw-bold textUpperCase">
                  Safe Travel, Unique Experiences
                </h2>
                <p className="text-sm-left text-md-center text-lg-center feature-paragraph fw-400 mb-2 paragraph">
                  Indulge in unique experiences and travel to refreshing yoga
                  retreats at your favorite destinations.
                </p>
                <UniqueExperiencesSec />
              </div>
            </div>
            <div className="col-12 bg-white py-3 py-sm-3 py-md-5 py-lg-5">
              <div className="container" style={{ position: "relative" }}>
                <h2 className="text-sm-left text-md-center text-lg-center feature-title fw-bold textUpperCase">
                  Discover Retreats across all styles
                </h2>
                <h4 className="text-sm-left text-md-center text-lg-center feature-paragraph fw-400 brownish-grey">
                  Choose from a variety of retreats across all styles at one
                  destination!
                </h4>
                <DiscoverRetreats />
              </div>
            </div>
            <div className={`${styles.cta_section} py-5`}>
              <div className="container py-5">
                <h4 className="m-0">Retreats</h4>
                <h2 className=" fw-bold m-0 text-white textUpperCase">
                  There’s so much <br /> that you can do on a retreat!
                </h2>
                <p className="mt-2 fw-400 textUpperCase">
                  Activities and accommodations
                </p>
                {/* <Link href=""> */}
                <button
                  className={`btn-light py-2 rounded border-0 fw-semibold px-3 fs-15 paragraph`}
                  onClick={() => _handleroute(null, "Yoga Retreat")}
                >
                  Learn More
                </button>
                {/* </Link> */}
              </div>
            </div>
            <ReviewBlock />
            <StillSearching />
          </div>
        </MainLayout>
      </div>
    );
  }
}

export async function getServerSideProps({ req, query }: any) {
  log("query : ", query);
  const datacookies = parseCookies(req);
  log("datacookies : ", datacookies.currencyType);
  const [product, Image] = await Promise.all([
    getFeaturedRetreatsAndTeacherTraingingCard(
      datacookies.token,
      req?.headers?.cookie
    ),
    GetSiteSettingServerSideRequest(datacookies.token),
  ]);
  let data = await product.data;
  let adminsideData = await Image.data;
  return {
    props: {
      data,
      adminsideData,
      title: `Book Your Next Yoga Retreat & Enjoy A Trusted Experience.`,
      description: `Experience the most amazing yoga retreats with 100+ retreat options available at Book Your Retreat. Check with reviews and get trusted feedback before booking. `,
    },
  };
}
